<script>
  import axios from "axios"
  export default {
    data() {
      return {
        url: null,
        code: null
      }
    },
    methods: {
      submit() {
        axios
          .put("https://url-shortener-nginx-jopplt.cloud.okteto.net/encode", {url: this.url})
          .then(result => {
            this.code = result.data
          })
          .catch(reason => {
            console.log(reason)
          })
      }
    }
  }
</script>
  
<template>
  <input v-model="url">
  <button @click="submit">Submit</button>
  <br />
  {{ code }}
</template>