<script>
    import axios from 'axios'
    import { useRoute } from 'vue-router'

    export default {
        mounted: () => {
            const route = useRoute()
            console.log(route.params.code)
            axios
                .get("https://url-shortener-nginx-jopplt.cloud.okteto.net/decode/" + route.params.code)
                .then(result => {
                    window.location.href = result.data
                })
                .catch(reason => {
                    console.log(reason)
                })
        },
    }
</script>